.weightLossAssistant {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

.weightLossRecommendationsParent {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px gray;
    border-radius: 5px;
    width: 25rem;
    height: 5rem;
  
  }
  .weightLossRecommendations {
  
    display: flex;
    font-weight: bold;
    gap: 0.5rem;
  
  }
  
  .cutCalorieWeightAndStepsInput {
    display: flex;
    gap: 0.5rem;
    border: solid 1px gray;
    border-radius: 5px;
    width: 25rem;
    height: 5rem;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  
  }
  
  .cutInputBoxes {
    width: 7rem;
    height: 2rem;
    border: solid 1px lightgray;
    border-radius: 3px;
  }

  .plannedExpectedActualParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px gray;
    width: 25rem;
    border-radius: 5px;
    height: 4rem;
  }
  
  .plannedExpectedActual {
    display: flex;
    gap: 0.5rem;
    font-size: 0.92rem;
  
  
  
  }

  @media screen and (max-width: 600px){
    .weightLossAssistant {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  
  .weightLossRecommendationsParent {
  
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: solid 1px gray;
      border-radius: 5px;
      width: 24rem;
      height: 5rem;
    
    }
    .weightLossRecommendations {
    
      display: flex;
      font-weight: bold;
      gap: 0.5rem;
    
    }
    
    .cutCalorieWeightAndStepsInput {
      display: flex;
      gap: 0.5rem;
      border: solid 1px gray;
      border-radius: 5px;
      width: 24rem;
      height: 5rem;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    
    }
    
    .cutInputBoxes {
      width: 7rem;
      height: 2rem;
      border: solid 1px lightgray;
      border-radius: 3px;
    }
  
    .plannedExpectedActualParent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: solid 1px gray;
      width: 24rem;
      border-radius: 5px;
      height: 4rem;
    }
    
    .plannedExpectedActual {
      display: flex;
      gap: 0.5rem;
      font-size: 0.85rem;

  }
}