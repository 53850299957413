.phaseDayWeekCreation {
    display: flex;
    width: 6rem;
    gap: 5px;
    margin-top: 0.7rem;
  }
  
  .exerciseCreation {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    gap: 5px;
  }
  
  .exerciseCreationNote {
    margin-bottom: 1.5rem;
  }