header, footer {
  
    width: 100%;
    background-color: yellow;
    padding: 1rem;
    z-index: 1;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

  .title {
    font-size: 1.5rem;
    color: black;
  }