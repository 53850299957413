.home {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  
  }
  
  .welcome {
    font-size: 1.5rem;
    color: black;
  
  }