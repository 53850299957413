body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 80vh;
}


.editValue {
  display: flex;
  gap: 15px;
  align-items: center;
}

button {
  background-color: yellow;
  color: black;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
}

@media screen and (max-width: 600px) {
  .phaseDayWeekSelect {
    width: 100%;
    font-size: 14px;
    padding: 5px;

  }

  .exerciseList {
    width: 50vh;
  }

  .exerciseInputtext {
    width: 5.5rem;
    border: solid 1px gray;
    border-radius: 4px;
  }
}


