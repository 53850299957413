.exerciseTest {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  
  }
  
  .exercisesList {
    background-color: white;
    border: solid 1px white;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 40vh;
    list-style-type: none;
    padding: 5px;
    font-size: 0.75rem;
    
  }
  
  .exerciseName {
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
  }
  
  .exerciseSubmit {
    display: flex;
    justify-content: center;
  }
  
  
  
  .phaseDayWeek {
    display: flex;
    justify-content: center;
  }
  
  .exerciseInput {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    width: 8rem;
    margin-top: 10px;
  
  }

  .currPhaseDayWeek {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 0.75rem;
  }

  .currPhaseDayWeekChildren {
    display: flex;
    gap: 0.5rem;
  }
  
  .advEntryInput {
    margin: 5px;
    width: 6rem;
  
  }
  
  .exerciseInputtext {
    width: 6rem;
    border: solid 1px gray;
    border-radius: 4px;
  }

  .phaseDayWeekDropdown {
    width: 8rem;
  }

  .linkButton {
    background: none;
    border: none;
    color: blue;
    font-size: inherit;
    font-family: inherit;
    margin: 1rem 0;
    padding: 0;
    
  }

  .linkButton:hover, .linkButton:focus {
    color: darkblue;
    outline: none;
  }

  .viewSubmission {
    display: flex;
    justify-content: center;
    margin-top:0.5rem;
  }



  @media screen and (max-width: 600px) {
    .phaseDayWeekSelect {
      width: 100%;
      font-size: 14px;
      padding: 5px;
  
    }
  
    .exerciseList {
      width: 50vh;
    }
  
    .exerciseInputtext {
      width: 5.5rem;
      border: solid 1px gray;
      border-radius: 4px;
    }
  }