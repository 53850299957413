.weightGain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .mainAndBulkCalories {
    display: flex;
    justify-content: center;
    width: 25rem;
    gap: 10px;
    border: solid 1px gray;
    border-radius: 5px;
  }
  
  .bulkCalorieAndWeightInput {
    display: flex;
    justify-content: center;
    border: solid 1px gray;
    width: 25rem;
    height: 5rem;
    border-radius: 5px;
  
  }
  
  .bulkCalorieAndWeightChildren {

    display: flex;
    margin-left: 0.5rem;
    align-items: center;
  
  }
  
  .plannedExpectedActualParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px gray;
    width: 25rem;
    border-radius: 5px;
    height: 4rem;
  }
  
  .plannedExpectedActual {
    display: flex;
    gap: 0.5rem;
    font-size: 0.92rem;
  
  
  
  }
@media screen and (max-width: 600px) {

  .weightGain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .mainAndBulkCalories {
    display: flex;
    justify-content: center;
    width: 24rem;
    gap: 10px;
    border: solid 1px gray;
    border-radius: 5px;
  }
  
  .bulkCalorieAndWeightInput {
    display: flex;
    justify-content: center;
    border: solid 1px gray;
    width: 24rem;
    height: 5rem;
    border-radius: 5px;
  
  }
  
  .bulkCalorieAndWeightChildren {

    display: flex;
    margin-left: 0.5rem;
    align-items: center;
  
  }
  
  .plannedExpectedActualParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px gray;
    width: 24rem;
    border-radius: 5px;
    height: 4rem;
  }
  
  .plannedExpectedActual {
    display: flex;
    gap: 0.5rem;
    font-size: 0.85rem;
  
  }
}